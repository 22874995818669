import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/reducers/AppState';
import { getEvent } from 'src/app/store/actions/event.actions';
import { selectEvent } from 'src/app/store/selectors/event.selectors';
import { Observable } from 'rxjs/internal/Observable';
import { EventData } from 'src/app/core/interfaces/EventData';
import { getAllEventClasses } from 'src/app/store/actions/classes.actions';
import { clearEntries, setCanNavigate } from 'src/app/store/actions/can-navigate.action';
import { addContestantEntry } from 'src/app/store/actions/entries.actions';
import * as uuid from "uuid";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  public eventData: any;

  constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router, public event: EventService) {
    this.store.pipe(select(selectEvent)).subscribe(data => {
      this.eventData = data;
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    const eventId = routeParams.get('id');

    if (eventId) {
      localStorage.setItem('eventId', eventId);
    }
    this.store.dispatch(clearEntries())

    this.store.dispatch(getEvent({ id: eventId }));
    this.store.dispatch(getAllEventClasses({ id: eventId }));
    this.store.dispatch(setCanNavigate({ status: true }));
    this.store.dispatch(addContestantEntry({contestantId: uuid.v4()}));
  }



  continueToEntryForm() {
    this.router.navigate(['/entry']);
  }

  isLive() {
    if (this.eventData) {
      if (this.router.url.split('/')[1] == "admin") {
        return true
      } else {

        // Is opening time before current date time
        if (moment.utc(this.eventData['openingDateTime']).local().isBefore()) {


          //is closing time before current date time
          if (moment().isAfter(moment.utc(this.eventData['closingDateTime']).local())) {

            return false;

          } else {

            return true;
          }

        } else {

          return false;

        }
      }
    } else {
      return false;
    }

  }

}
